import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from '../AllProducts/AllProducts'
import { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import { useSelector } from 'react-redux'
import ReportsModal from '../../components/Modal/ReportsModal'
import { Empty, Button as AntButton } from 'antd'

const WhsReports = () => {
	const { getMe } = useSelector((state) => state.main)

	const errorRef = useRef()
	const viewRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [imeiClient, setImeiClient] = useState('')
	const [product, setProduct] = useState('')
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)

	const search = () => {
		setIsLoading(true)
		// &$skip=${currentPage}

		customFuncsApi
			.get(
				`XsSql/getItemsOnHand?ItemName=${product}&isOnHand=true&IntrSerial=${imeiClient}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=2000',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value || []
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [currentPage])

	const viewItem = (v) => {
		viewRef.current?.open(v)
	}

	const handleSearch = () => {
		search()
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">Склад остатка</p>
					<div className="flex items-center justify-between flex-col sm:flex-row gap-5 mt-5">
						<div className="flex items-center gap-5 flex-col sm:flex-row w-full">
							<input
								className={
									'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-full sm:w-auto'
								}
								type="text"
								placeholder={'Наименование товара'}
								defaultValue={product}
								onChange={(v) => setProduct(v.target.value)}
							/>
							<input
								className={
									'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-full sm:w-auto'
								}
								type="text"
								placeholder={'ИМЕИ'}
								defaultValue={imeiClient}
								onChange={(v) => {
									setImeiClient(v.target.value)
								}}
							/>
						</div>

						<AntButton
							className="bg-[#0A4D68] text-white rounded-md w-full sm:w-[100px] h-10"
							onClick={handleSearch}
							loading={isLoading}
						>
							Поиск
						</AntButton>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-10">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код товара
											</th>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Общее Количество
											</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 ? (
											data.map((v, i) => {
												return (
													<tr
														key={i}
														onClick={() => viewItem(v)}
														className="bg-white border-b  hover:bg-gray-50 "
													>
														<td
															scope="row"
															className="px-6 py-4 font-medium text-gray-900 "
														>
															{get(v, 'ItemCode', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'ItemName', '')} {get(v, 'U_Color', '')}{' '}
															{get(v, 'U_Memory', '')}{' '}
															{get(v, 'U_Condition', '')}
														</td>
														<td
															scope="row"
															className="px-6 py-4 font-medium text-gray-900 "
														>
															{get(v, 'SumOnHand', '-')}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={'Ничего не найдено'}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{/* <div className="topCard">
								<div className="flex items-center gap-2 ">
									<AntButton
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</AntButton>
									<p>{currentPage / 20 + 1}</p>
									<Button
										disabled={data.length < 20}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div> */}
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ReportsModal
					getRef={(ref) => (viewRef.current = ref)}
					onUpdated={() => search(data.currentPage, data.data)}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default WhsReports
