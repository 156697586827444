import React, { memo, useEffect, useState, useRef } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import moment from 'moment'
import { get } from 'lodash'
import api from '../../../api'
import { ErrorModal, SuccessModal } from '../../../components/Modal'
import { Button as AntButton } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}

const DeleteTask = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	executer = [],
}) => {
	const { getMe } = useSelector((state) => state.main)
	const navigate = useNavigate()

	const errorRef = useRef()
	const succesRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [datas, setData] = useState(null)
	const [loadingBtn, setLoadingBtn] = useState(false)

	useEffect(() => {
		const ref = {
			open: (v) => {
				setData(v)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const deleteTask = () => {
		setLoadingBtn(true)
		api
			.patch(`Invoices(${datas?.DocEntry})`, {
				U_Is_Proved: 'NO',
			})
			.then(() => {
				succesRef.current?.open('Задача успешно удалено')

				onClose()
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setLoadingBtn(false)
			})
	}

	const Todo = () => {
		navigate('/viewRecoveryItem', {
			state: {
				data: {
					InstlmntID: datas?.Installmnt,
					DocEntry: datas?.DocEntry,
					U_Task: datas?.U_Task,
					U_Dunner: datas?.U_Dunner,
					U_DeadLine: datas?.U_DeadLine,
					U_StatusDunner: datas?.U_StatusDunner,
				},
				executer,
				isTask: true,
			},
		})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<h2 className="font-bold text-xl text-center mb-5">{'Задача'}</h2>

					<div
						// onClick={() => viewItem(v)}
						className="bg-white   h-fit border mt-5 p-5 drop-shadow-xl rounded-3xl cursor-pointer"
					>
						<div className="flex gap-4">
							<p className="font-bold">Клиент :</p>
							<p className="text-blue-600 font-medium">
								{get(datas, 'CardName', '-')}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Приемщик :</p>
							<p className="text-blue-600 font-medium">
								{get(datas, 'DunnerName', '-')}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Дата продажи :</p>
							<p className="text-blue-600 font-medium">
								{moment(datas?.DocDate).format('DD-MM-YYYY')}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Имеи :</p>
							<p className="text-blue-600 font-medium">
								{get(datas, 'IntrSerial', '-') || '-'}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Задача :</p>
							<p className="text-blue-600 font-medium">
								{get(datas, 'U_Task', '-') || '-'}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Дедлайн :</p>
							<p
								className={`${moment(datas?.U_DeadLine).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') ? 'text-green-500 font-bold' : moment(datas?.U_DeadLine).format('DD-MM-YYYY') < moment().format('DD-MM-YYYY') ? 'text-red-500 font-bold' : 'text-blue-600 font-medium'}`}
							>
								{(datas?.U_DeadLine &&
									moment(datas?.U_DeadLine).format('DD-MM-YYYY')) ||
									'-'}
							</p>
						</div>
						<div className="flex gap-4">
							<p className="font-bold">Cтатус :</p>
							<p className="text-blue-600 font-medium">
								{get(datas, 'U_StatusDunner', '-') === 'NotYet'
									? 'Bajarilmagan'
									: 'Bajarilgan' || '-'}{' '}
							</p>
						</div>
					</div>

					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
					get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ||
					get(getMe, 'Department2.Name', '') === 'Undiruvchi2' ||
					get(getMe, 'Department2.Name', '') === 'Undiruvchi3' ? (
						<div className="flex items-center justify-between">
							<AntButton
								className="bg-red-500 text-white p-2 mt-5 h-10 w-full sm:w-[100px]"
								onClick={deleteTask}
								loading={loadingBtn}
							>
								{'Удалить'}
							</AntButton>
							<AntButton
								className="bg-[#0A4D68] text-white p-2 mt-5 h-10 w-full sm:w-[150px]"
								onClick={Todo}
							>
								{'Выполнить'}
							</AntButton>
						</div>
					) : get(getMe, 'Department2.Name', '') === 'Undiruv' ? (
						<div className="flex items-center justify-end">
							<AntButton
								className="bg-[#0A4D68] text-white p-2 mt-5 h-10 w-full sm:w-[150px]"
								onClick={Todo}
							>
								{'Выполнить'}
							</AntButton>
						</div>
					) : (
						''
					)}
				</div>
			</CreateUserStyle>

			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
				}}
			/>
			<SuccessModal
				getRef={(r) => {
					succesRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
				}}
			/>
		</Modal>
	)
}

export default memo(DeleteTask)
