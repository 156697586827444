import React, { memo, useEffect, useState, useRef } from 'react'
import CreateUserStyle from '../CreateUser/CreateUserStyle'
import Modal from 'react-modal'
import moment from 'moment'
import { get } from 'lodash'
import api from '../../../api'
import { ErrorModal, SuccessModal } from '..'
import { Button as AntButton, Input, Popconfirm } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}

const ChangePrice = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	executer = [],
}) => {
	const { getMe } = useSelector((state) => state.main)
	const navigate = useNavigate()

	const errorRef = useRef()
	const succesRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [datas, setData] = useState(null)
	const [price, setPrice] = useState('')
	const [loadingBtn, setLoadingBtn] = useState(false)

	useEffect(() => {
		const ref = {
			open: (v) => {
				setData(v)
				setPrice(v?.U_ShopfrontPrice)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const patchPrice = () => {
		setLoadingBtn(true)
		api
			.patch(`PurchaseInvoices(${datas?.DocEntry})`, {
				DocumentLines: [
					{
						LineNum: datas?.LineNum,
						U_ShopfrontPrice: parseFloat(price),
					},
				],
			})
			.then(() => {
				succesRef.current?.open('Цена успешно изменена')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setLoadingBtn(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<h2 className="font-bold text-xl text-center mb-5">
						{'Изменить цену'}
					</h2>

					<div className="flex justify-center gap-2">
						<Input
							value={price}
							className="w-[200px]"
							onChange={(e) => setPrice(e.target.value)}
						/>
					</div>
					<div className="flex justify-center gap-5 mt-10">
						<AntButton
							type="primary"
							className="!bg-red-600 h-10"
							onClick={onClose}
						>
							Отменить
						</AntButton>
						<Popconfirm
							title="Изменить цену"
							description="Вы уверены, что хотите изменить цену?"
							okText="Да"
							cancelText="Нет"
							onConfirm={() => {
								patchPrice()
							}}
						>
							<AntButton
								type="primary"
								loading={loadingBtn}
								className="!bg-blue-600 h-10"
							>
								Изменить
							</AntButton>
						</Popconfirm>
					</div>
				</div>
			</CreateUserStyle>

			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
				}}
			/>
			<SuccessModal
				getRef={(r) => {
					succesRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
				}}
			/>
		</Modal>
	)
}

export default memo(ChangePrice)
