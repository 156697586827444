import { useEffect, useRef, useState } from 'react'
import Layout from '../../components/Layout'
import ReportOnCollectors from './ReportOnCollectors'
import { generateUrlParams } from '../../lib/GenerateUrlParams'
import api from '../../api'
import { ClipLoader } from 'react-spinners'
import { get } from 'lodash'
import { ErrorModal } from '../../components/Modal'
import { BsArrowLeft, BsArrowRight, BsSearch } from 'react-icons/bs'
import ReactApexChart from 'react-apexcharts'
import { formatNumberWithIntl } from '../../lib/FormatNumbersWithIntl'

const DunnersIndicatorAPI =
	'https://icomfort-new-back-prod.bis-apps.com/api/xsSql/getDunnersIndicator'

const NewMenu = () => {
	function getFirstDateOfMonthISO() {
		const today = new Date().toISOString().split('T')[0]
		const splittedToday = today.split('-')
		const firstDayOfTheMonth = `${splittedToday[0]}-${splittedToday[1]}-01`
		return firstDayOfTheMonth
	}

	const [dunnersIndicatorData, setDunnersIndicatorData] = useState([])
	const [isPending, setIsPending] = useState(false)
	const [pagination, setPagination] = useState(0)
	const itemsPerPage = 20

	const [startDate, setStartDate] = useState(getFirstDateOfMonthISO())
	const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [moneyGettersList, setMoneyGettersList] = useState([])
	const [gettersLoading, setGettersLoading] = useState(false)
	const [getterId, setGetterId] = useState(undefined)
	const [PieChartData, setPieChartData] = useState([])

	// Variable for charts
	const [cob, setCob] = useState(0) // Current Overdue Balance --> Plan
	const [mp, setMp] = useState(0) // Monthly Payment
	const [tcm, setTcm] = useState(0) // Total Collected Money --> Fact
	const [toa, setToa] = useState(0) // Total Overdue Amount
	const [barChartCategories, setBarChartCategories] = useState([])
	const [barChartSeries, setBarChartSeries] = useState([])
	const [barChartData, setBarChartData] = useState([])

	useEffect(() => {
		getData()
		getAllGetterMoney()
	}, [])

	const getAllGetterMoney = () => {
		setGettersLoading(true)
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=(Departments/Name eq 'Undiruv' or Departments/Name eq 'Undiruvchi1' or Departments/Name eq 'Undiruvchi3' or Departments/Name eq 'Undiruvchi2') and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const data = JSON.parse(res.data).value
				for (const item in data) {
					const moneyGetter = {
						EmployeeID: data[item]['EmployeesInfo']['EmployeeID'],
						FirstName: data[item]['EmployeesInfo']['FirstName'],
						LastName: data[item]['EmployeesInfo']['LastName'],
					}
					setMoneyGettersList((prev) => [...prev, moneyGetter])
				}
				setGettersLoading(false)
			})
			.catch((err) => {
				setGettersLoading(false)
				get(
					JSON.parse(err.response.data),
					'error.message',
					'Не удалось получить данные, попробуйте еще раз.',
				)
			})
	}

	const getData = () => {
		setCob(0)
		setMp(0)
		setTcm(0)
		setToa(0)
		setPieChartData([])
		setBarChartData([])
		setIsPending(true)
		const url = generateUrlParams(DunnersIndicatorAPI, {
			startDate: startDate && endDate ? startDate : undefined,
			endDate: startDate && endDate ? endDate : undefined,
			collectorId: getterId,
		})

		api
			.get(url)
			.then((res) => {
				setIsPending(false)
				const data = JSON.parse(res.data)
				setDunnersIndicatorData(data.value ?? [])

				let currentOverdueBalance = 0
				let monthlyPayment = 0
				let totalCollectedMoney = 0
				let totalOverdueAmount = 0

				for (let i = 0; i < data.value.length; i += 1) {
					if (data.value[i]['Current Overdue Balance'] === null) {
						data.value[i]['Current Overdue Balance'] = 0
					} else {
						data.value[i]['Current Overdue Balance'] = data.value[i][
							'Current Overdue Balance'
						].replaceAll(',', '.')
					}

					currentOverdueBalance += Number(
						data.value[i]['Current Overdue Balance'],
					)
					monthlyPayment += Number(data.value[i]['Monthly Payment'])
					totalCollectedMoney += Number(data.value[i]['Total Collected Money'])
					totalOverdueAmount += Number(data.value[i]['Total Overdue Amount'])
				}
				setCob(Number(currentOverdueBalance.toFixed(2)))
				setMp(Number(monthlyPayment.toFixed(2)))
				setTcm(Number(totalCollectedMoney.toFixed(2)))
				setToa(Number(totalOverdueAmount.toFixed(2)))
			})
			.catch((err) => {
				setIsPending(false)
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	useEffect(() => {
		if (moneyGettersList.length && dunnersIndicatorData.length) {
			const documentGroups = dunnersIndicatorData.reduce((group, doc) => {
				if (!group[doc.CollectorId]) {
					group[doc.CollectorId] = []
				}
				group[doc.CollectorId].push(doc)
				return group
			}, {})

			const result = moneyGettersList
				.map((employee) => {
					const employeeDocuments = documentGroups[employee.EmployeeID] || []

					const Current_Overdue_Balance = employeeDocuments.reduce(
						(sum, curr) => sum + Number(curr['Current Overdue Balance']),
						0,
					)
					const Monthly_Payment = employeeDocuments.reduce(
						(sum, curr) => sum + Number(curr['Monthly Payment']),
						0,
					)
					const Total_Collected_Money = employeeDocuments.reduce(
						(sum, curr) => sum + Number(curr['Total Collected Money']),
						0,
					)
					const Total_Overdue_Amount = employeeDocuments.reduce(
						(sum, curr) => sum + Number(curr['Total Overdue Amount']),
						0,
					)
					return {
						id: employee.EmployeeID,
						documents: employeeDocuments,
						Current_Overdue_Balance: Number(Current_Overdue_Balance.toFixed(2)),
						Monthly_Payment: Number(Monthly_Payment.toFixed(2)),
						Total_Collected_Money: Number(Total_Collected_Money.toFixed(2)),
						Total_Overdue_Amount: Number(Total_Overdue_Amount.toFixed(2)),
						collectorName: `${employee.FirstName} ${employee.LastName}`,
					}
				})
				.filter((authorGroup) => authorGroup.documents.length > 0)
			const ctgrs = result.map((el) => el.collectorName)
			const plan = result.map((el) => el.Current_Overdue_Balance)
			const fact = result.map((el) => el.Total_Collected_Money)

			setBarChartSeries([
				{
					name: 'План',
					data: plan,
				},
				{
					name: 'Факт',
					data: fact,
				},
			])

			setBarChartCategories(ctgrs)
			setBarChartData(result)
			setPieChartData(result)
		}
	}, [moneyGettersList, dunnersIndicatorData])

	const paginatedData = dunnersIndicatorData.slice(
		pagination * itemsPerPage,
		(pagination + 1) * itemsPerPage,
	)

	return (
		<Layout>
			<ReportOnCollectors>
				<div
					className="container"
					style={{
						flex: 3,
					}}
				>
					<div className="productTitle">Отчет по коллекторам</div>
					<div className="chartsContainer">
						<div className="topChart" style={{ width: '100%' }}>
							<span>По всем коллекторам</span>
							{cob && tcm && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'start',
										gap: '16px',
									}}
								>
									<ReactApexChart
										options={{
											chart: {
												height: 350,
												type: 'radialBar',
											},
											colors: ['#3AE32B'],
											plotOptions: {
												radialBar: {
													hollow: {
														size: '70%',
													},
													dataLabels: {
														show: true,
														name: {
															fontSize: '22px',
														},
														value: {
															fontSize: '16px',
															color: '#111',
															formatter: (val) => `${val}%`,
														},
													},
												},
											},
											labels: ['Факт'],
										}}
										series={[((tcm / cob) * 100).toFixed(2)]}
										type="radialBar"
										height={350}
									/>
									<div
										style={{
											flex: '1',
											display: 'flex',
											flexDirection: 'column',
											gap: '16px',
											fontWeight: 'bold',
											fontSize: '12px',
										}}
									>
										<span>
											<span
												style={{
													color: '#D6322F',
												}}
											>
												План:
											</span>{' '}
											{formatNumberWithIntl(cob) || 0}
										</span>
										<span>
											<span
												style={{
													color: '#3AE32B',
												}}
											>
												Факт
											</span>{' '}
											{formatNumberWithIntl(tcm) || 0}
										</span>
									</div>
								</div>
							)}
							{/* {cob && tcm && (
								<ReactApexChart
									height={380}
									options={{
										chart: {
											width: 380,
											type: 'pie',
											height: 300,
										},
										colors: ['#D6322F', '#3AE32B'],
										fill: {
											colors: ['#D6322F', '#3AE32B'],
										},
										labels: [
											`План: ${formatNumberWithIntl(cob) || 0}`,
											// `Ежемесячный платеж: ${mp}`,
											`Факт ${formatNumberWithIntl(tcm) || 0}`,
											// `Общая сумма просроченной задолженности ${toa}`,
										],
										responsive: [
											{
												breakpoint: 480,
												options: {
													chart: { width: 200 },
													legend: { position: 'bottom' },
												},
											},
										],
									}}
									series={[
										cob || 0, // --> Plan
										// mp,
										tcm || 0, // --> Fact
										// toa
									]}
									type="pie"
								/>
							)} */}
							{PieChartData.map((el, idx) => {
								return (
									<div
										key={idx}
										style={{
											display: 'grid',
											gridTemplateColumns: '220px 1fr',
										}}
									>
										<span
											style={{
												margin: '0 4px',
												fontWeight: 500,
											}}
										>
											{el.collectorName} {':  '}
										</span>
										<span style={{ margin: '0 4px' }}>
											Собрал{' '}
											{Number((el.Total_Collected_Money * 100) / tcm).toFixed(
												1,
											)}
											% от общей суммы задолженности
										</span>
									</div>
								)
							})}
						</div>
						<div>
							{barChartData.length && (
								<ReactApexChart
									type="bar"
									height={350}
									options={{
										chart: {
											type: 'bar',
											height: 350,
										},

										plotOptions: {
											bar: {
												horizontal: false,
												columnWidth: '55%',
												borderRadius: 5,
												borderRadiusApplication: 'end',
											},
										},
										dataLabels: {
											enabled: false,
										},
										stroke: {
											show: true,
											width: 2,
											colors: ['transparent'],
										},
										xaxis: {
											categories: barChartCategories,
										},
										yaxis: {
											title: {
												text: '',
											},
										},
										colors: ['#D6322F', '#3AE32B'],
										fill: {
											colors: ['#D6322F', '#3AE32B'],
											opacity: 1,
										},
										tooltip: {
											y: {
												formatter: function (val) {
													return formatNumberWithIntl(val || 0)
												},
											},
										},
									}}
									series={barChartSeries}
								/>
							)}
						</div>
						{/* <div className="bottomCharts">
							{PieChartData.length &&
								PieChartData.map((el, idx) => {
									return (
										<div className="chartCard" key={idx}>
											<span style={{ margin: '0 4px' }}>
												{el.collectorName} {' - '}
											</span>
											<span style={{ margin: '0 4px' }}>
												{Number(
													(el.Current_Overdue_Balance * 100) /
														el.Total_Collected_Money,
												).toFixed(2)}
												% плана выполнено
											</span>
											<ReactApexChart
												height={200}
												options={{
													chart: {
														width: 300,
														type: 'pie',
													},
													colors: ['#D6322F', '#3AE32B'],
													fill: {
														colors: ['#D6322F', '#3AE32B'],
													},
													labels: [
														`План: ${formatNumberWithIntl(el.Current_Overdue_Balance || 0)}`,
														// `Ежемесячный платеж: ${el.Monthly_Payment}`,
														`Факт ${formatNumberWithIntl(el.Total_Collected_Money || 0)}`,
														// `Общая сумма просроченной задолженности ${el.Total_Overdue_Amount}`,
													],
													responsive: [
														{
															breakpoint: 280,
															options: {
																legend: {
																	position: 'bottom',
																},
															},
														},
													],
												}}
												series={[
													el.Current_Overdue_Balance || 0,
													// el.Monthly_Payment,
													el.Total_Collected_Money || 0,
													// el.Total_Overdue_Amount,
												]}
												type="pie"
											/>
										</div>
									)
								})}
						</div> */}
					</div>
				</div>
				<div
					className="container"
					style={{
						flex: 2,
					}}
				>
					<div className="productTitle">Отчет по коллекторам</div>
					<div className="searchCard">
						<div className="inputContainer">
							<div className="inputWrapper">
								<label name="from" style={{ fontSize: 18 }}>
									Дата начала
								</label>
								<input
									className="input"
									type="date"
									name="from"
									id="from"
									value={startDate}
									onChange={(e) => setStartDate(e.target.value)}
								/>
							</div>
							<div className="inputWrapper">
								<label name="to" style={{ fontSize: 18 }}>
									Дата окончания
								</label>
								<input
									className="input"
									type="date"
									name="to"
									id="to"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
							<div className="inputWrapper">
								<label name="to" style={{ fontSize: 18 }}>
									Сборщик
								</label>
								{!gettersLoading ? (
									<select
										style={{
											padding: '10px',
											fontSize: '16px',
											border: '1px solid #ccc',
											borderRadius: '4px',
											backgroundColor: '#f9f9f9',
											color: '#333',
											outline: 'none',
											width: '100%',
											cursor: 'pointer',
										}}
										onChange={(e) => setGetterId(e.target.value)}
									>
										<option value="" selected>
											Сборщик
										</option>
										{moneyGettersList.map((el, idx) => (
											<option key={idx} value={el.EmployeeID}>
												{el.FirstName} {el.LastName}
											</option>
										))}
									</select>
								) : (
									<span
										style={{
											backgroundColor: '#f9f9f9',
											width: '100%',
											padding: '20px 10px',
											border: '1px solid #ccc',
											borderRadius: '4px',
										}}
									></span>
								)}
							</div>
							<div>
								<button className="searchButton" onClick={getData}>
									<BsSearch />
								</button>
							</div>
						</div>
						<div className="paginationContainer">
							<button
								onClick={() => setPagination((prev) => Math.max(prev - 1, 0))} // Prevent negative pages
								disabled={pagination === 0}
							>
								<BsArrowLeft />
							</button>
							<span>{pagination + 1}</span>
							<button
								onClick={() =>
									setPagination((prev) =>
										(prev + 1) * itemsPerPage < dunnersIndicatorData.length
											? prev + 1
											: prev,
									)
								} // Prevent going beyond available data
								disabled={
									(pagination + 1) * itemsPerPage >= dunnersIndicatorData.length
								}
							>
								<BsArrowRight />
							</button>
						</div>
					</div>
					{!isPending ? (
						<table className="table">
							<thead>
								<tr>
									<th>Коллектор</th>
									<th>Сумма сбора</th>
									<th>Имя клиента</th>
									<th>Ежемесячный платеж</th>
									<th>Общая сумма собранных денег</th>
									<th>Общая сумма просроченной задолженности</th>
								</tr>
							</thead>
							<tbody>
								{paginatedData.map((row, index) => (
									<tr key={index} className="row">
										<td>{row['Collector']}</td>
										<td>{row['Current Overdue Balance']}</td>
										<td>{row['Customer Name']}</td>
										<td>{Number(row['Monthly Payment']).toFixed(2)}</td>
										<td>{Number(row['Total Collected Money']).toFixed(2)}</td>
										<td>{Number(row['Total Overdue Amount']).toFixed()}</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					)}
				</div>
			</ReportOnCollectors>
			<ErrorModal
				getRef={(r) => (errorRef.current = r)}
				onConfirm={() => {
					setIsPending(false)
					getData()
				}}
			/>
		</Layout>
	)
}

export default NewMenu
