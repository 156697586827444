import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from './AllProducts'
import { Button } from 'antd'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { isAxiosError } from 'axios'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import DeleteTask from '../../components/Modal/DeleteTask'
import AddTask from '../../components/Modal/AddTask'
import PaginationControls from '../../components/PaginationControls'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

const handleBusinessPartner = _.debounce((searchFunc, value) => {
	searchFunc(value)
}, 1000)

const Task = () => {
	const { getMe } = useSelector((state) => state.main)

	const dateFormat = 'YYYY-MM-DD'
	const startMonth = dayjs().startOf('month').format(dateFormat)
	const startYear = dayjs().startOf('year').format(dateFormat)
	const today = dayjs().format(dateFormat)

	const errorRef = useRef()
	const deleteRef = useRef()
	const addTask = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingNotFinished, setIsLoadingNotFinished] = useState(false)
	const [clientLoading, setClientLoading] = useState(false)
	const [client, setClient] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [employeeID, setEmployeeID] = useState(
		get(getMe, 'Department2.Name', '') === 'Undiruv'
			? get(getMe, 'FirstName', '')
			: '',
	)
	const [imeiClient, setImeiClient] = useState('')
	const [finished, setFinished] = useState([])
	const [notFinished, setNotFinished] = useState([])
	const [currentPage, setCurrentPage] = useState(0)
	const [currentPageNotFinish, setCurrentPageNotFinish] = useState(0)
	const [clientsData, setClientsData] = useState([])
	const [executors, setExecutors] = useState([])
	const [startDate, setStartDate] = useState(startYear)
	const [endDate, setEndDate] = useState(today)
	const [startPromisedDate, setStartPromisedDate] = useState(startYear)
	const [endPromisedDate, setEndPromisedDate] = useState(today)
	const handleStartDateChange = (date) => {
		setStartDate(date ? date.format(dateFormat) : null)
	}

	const handleEndDateChange = (date) => {
		setEndDate(date ? date.format(dateFormat) : null)
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			handleStartDateChange(start)
			handleEndDateChange(end)
		} else {
			console.error('Clear in Dashboard Tab1')
		}
	}

	const handleStartPromisedDateChange = (date) => {
		setStartPromisedDate(date ? date.format(dateFormat) : null)
	}

	const handleEndPromisedDateChange = (date) => {
		setEndPromisedDate(date ? date.format(dateFormat) : null)
	}
	const onRangePromisedDateChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			handleStartPromisedDateChange(start)
			handleEndPromisedDateChange(end)
		} else {
			console.error('Clear in Dashboard Tab1')
		}
	}
	const getFinishedDuner = () => {
		setIsLoading(true)
		const url = `xsSql/getTasks`
		const params = {
			cardName: client,
			// startDate: startDate,
			// endDate: endDate,
			deadLineStartDate: startPromisedDate,
			deadLineEndDate: endPromisedDate,
			dunner: employeeID,
			hasTask: 'Y',
			isProved: 'Yes',
			imei: imeiClient,
			dunnerStatus: 'Done',
			$skip: currentPage,
		}
		customFuncsApi
			.get(url, { params })
			.then((res) => {
				const resData = res.data.value || []
				console.log(res.data)
				setFinished(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	const getInvoices = () => {
		// setIsLoading(true)
		api
			.get(`Invoices`)
			.then((res) => {
				const resData = res.data.value || []
				// setFinished(resData)
				// setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				// setIsLoading(false)
			})
	}

	const getNotFinishedDuner = () => {
		setIsLoadingNotFinished(true)
		const url = `xsSql/getTasks`
		const params = {
			cardName: client,
			// startDate: startDate,
			// endDate: endDate,
			deadLineStartDate: startPromisedDate,
			deadLineEndDate: endPromisedDate,

			dunner: employeeID,
			hasTask: 'Y',
			isProved: 'Yes',
			imei: imeiClient,
			dunnerStatus: 'NotYet',
			$skip: currentPageNotFinish,
		}

		customFuncsApi
			.get(url, {
				params,
			})
			.then((res) => {
				const resData = res.data.value
				setNotFinished(resData)

				setIsLoadingNotFinished(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoadingNotFinished(false)
			})
	}

	const businessPartners = async (value) => {
		setClientLoading(true)
		api
			.get(`BusinessPartners`, {
				params: {
					$select: 'CardCode, CardName, U_Telephone',
					$filter: `contains(CardName, '${value}') or contains(U_Telephone, '${value}') or contains(CardCode, '${value}') and Frozen eq 'tNO'`,
				},
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				setClientsData(JSON.parse(res.data).value || [])
				setClientLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setClientLoading(false)
			})
	}

	useEffect(() => {
		getAllGetterMoney()
	}, [])

	const getAllGetterMoney = () => {
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=(Departments/Name eq 'Undiruv' or Departments/Name eq 'Undiruvchi1' or Departments/Name eq 'Undiruvchi3' or Departments/Name eq 'Undiruvchi2') and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				if (get(getMe, 'Department2.Name', '') === 'Undiruv') {
					const data = resData.filter(
						(v) => v.EmployeesInfo.EmployeeID === get(getMe, 'EmployeeID', ''),
					)
					setExecutors(data)
				} else setExecutors(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	useEffect(() => {
		getNotFinishedDuner()
	}, [currentPageNotFinish])
	useEffect(() => {
		getFinishedDuner()
		getInvoices()
	}, [currentPage])

	const viewItem = (v) => {
		deleteRef.current?.open(v)
	}

	const newDatas = () => {
		if (finished.length >= 20) {
			setCurrentPage(currentPage + 20)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (finished.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage(currentPage - 20)
		}
	}

	const newDatasNotFinished = () => {
		if (notFinished.length >= 20) {
			setCurrentPageNotFinish(currentPageNotFinish + 20)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldDataNotFinished = () => {
		if (notFinished.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPageNotFinish(currentPageNotFinish - 20)
		}
	}

	const handleSearch = () => {
		getFinishedDuner()
		getNotFinishedDuner()
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">Задачи</p>
					<div className="flex items-center justify-between">
						<div className="flex items-end flex-col sm:flex-row gap-4 my-8">
							<div className="relative">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Клиент
								</p>
								<input
									className={
										'border-[1px] border-[#d9d9d9] p-2 rounded-md w-[200px]'
									}
									type="text"
									placeholder="Клиент"
									value={client}
									onChange={(e) => {
										setClient(e.target.value)
										handleBusinessPartner(businessPartners, e.target.value)
									}}
								/>
								{client && (
									<div className="absolute top-16 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{clientsData.map((v, i) => {
											return (
												<div
													key={i}
													className="p-2 border-b cursor-pointer hover:bg-gray-100"
													onClick={() => {
														setClientCode(v.CardCode)
														setClient(v.CardName)
														setClientsData([])
													}}
												>
													{v.CardName}
												</div>
											)
										})}
									</div>
								)}
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Имеи</p>

								<input
									className={
										'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-[150px]'
									}
									type="text"
									placeholder="Имеи"
									defaultValue={imeiClient}
									onChange={(v) => {
										setImeiClient(v.target.value)
									}}
								/>
							</div>
							{/* <div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								Дата продажи
							</p>

							<RangePicker
								value={[
									startDate ? dayjs(startDate, dateFormat) : null,
									endDate ? dayjs(endDate, dateFormat) : null,
								]}
								format={dateFormat}
								onChange={onRangeChange}
							/>
						</div> */}

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дедлайн
								</p>
								<RangePicker
									value={[
										startPromisedDate
											? dayjs(startPromisedDate, dateFormat)
											: null,
										endPromisedDate ? dayjs(endPromisedDate, dateFormat) : null,
									]}
									format={dateFormat}
									onChange={onRangePromisedDateChange}
								/>
							</div>
							{get(getMe, 'Department2.Name', '') !== 'Sherik' && (
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Приёмщик
									</p>
									<select
										name="executer"
										id="executer"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[200px]'
										}
										onChange={(v) => {
											setEmployeeID(v.target.value)
										}}
										value={employeeID}
									>
										<option></option>

										{executors.map((v, i) => {
											return (
												<option
													//  ${get(v, 'EmployeesInfo.LastName', 'Surename')}
													value={`${get(v, 'EmployeesInfo.FirstName', 'Name')}`}
													key={i}
													selected={v.EmployeesInfo.FirstName === employeeID}
												>
													{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
													{get(v, 'EmployeesInfo.LastName', 'Surename')}
												</option>
											)
										})}
									</select>
								</div>
							)}
							<Button
								className={'bg-[#0A4D68] w-[100px] h-10 text-white rounded-lg '}
								onClick={() => handleSearch()}
								loading={isLoading || isLoadingNotFinished}
							>
								Поиск
							</Button>
						</div>
						<Button
							className={
								'bg-[#0A4D68] w-[100px] h-10 text-white rounded-lg mt-7'
							}
							onClick={() => addTask.current.open()}
						>
							Добавить
						</Button>
					</div>
					{/* <div className="w-full flex justify-end">
						<Button
							className={'bg-[#0A4D68] w-[100px] h-10 text-white rounded-lg '}
							onClick={() => addTask.current.open()}
						>
							Добавить
						</Button>
					</div> */}
					<div className="flex items-start gap-10 w-full">
						<div className="w-full">
							<div className="flex flex-col gap-4 w-full">
								<h1 className="text-2xl font-bold ">Не завершенные</h1>
								{isLoadingNotFinished ? (
									<ClipLoader
										loading={true}
										size={75}
										aria-label="Loading Spinner"
										className={'loader'}
										data-testid="loader"
									/>
								) : (
									notFinished.map((v, i) => {
										const deadLine = v.U_DeadLine
											? moment(v.U_DeadLine).format('DD-MM-YYYY')
											: ''
										const docDate = moment(v.DocDate).format('DD-MM-YYYY')

										return (
											<div
												key={i}
												onClick={() => viewItem(v)}
												className="bg-white   h-fit border p-5 drop-shadow-xl rounded-3xl cursor-pointer"
											>
												<div className="flex gap-4">
													<p className="font-bold">Клиент :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'CardName', '-')}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Приемщик :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'DunnerName', '-')}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Дата продажи :</p>
													<p className="text-blue-600 font-medium">{docDate}</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Имеи :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'IntrSerial', '-') || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Задача :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'U_Task', '-') || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Дедлайн :</p>
													<p
														className={`${deadLine === moment().format('DD-MM-YYYY') ? 'text-green-500 font-bold' : deadLine < moment().format('DD-MM-YYYY') ? 'text-red-500 font-bold' : 'text-blue-600 font-medium'}`}
													>
														{deadLine || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Cтатус :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'U_StatusDunner', '-') === 'NotYet'
															? 'Bajarilmagan'
															: 'Bajarilgan' || '-'}
													</p>
												</div>
											</div>
										)
									})
								)}
							</div>
							<PaginationControls
								currentPage={currentPageNotFinish}
								onPrevious={oldDataNotFinished}
								onNext={newDatasNotFinished}
								disablePrevious={currentPageNotFinish === 0}
								disableNext={notFinished.length < 10}
							/>
						</div>

						<div className="w-full">
							<div className="flex flex-col gap-4 w-full">
								<h1 className="text-2xl font-bold ">Завершенные</h1>
								{isLoading ? (
									<ClipLoader
										loading={true}
										size={75}
										aria-label="Loading Spinner"
										className={'loader'}
										data-testid="loader"
									/>
								) : (
									finished.map((v, i) => {
										const deadLine = v.U_DeadLine
											? moment(v.U_DeadLine).format('DD-MM-YYYY')
											: ''
										const docDate = moment(v.DocDate).format('DD-MM-YYYY')

										return (
											<div
												key={i}
												// onClick={() => viewItem(v)}
												className="bg-white   h-fit border p-5 drop-shadow-xl rounded-3xl cursor-pointer"
											>
												<div className="flex gap-4">
													<p className="font-bold">Клиент :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'CardName', '-')}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Приемщик :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'DunnerName', '-')}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Дата продажи :</p>
													<p className="text-blue-600 font-medium">{docDate}</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Имеи :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'IntrSerial', '-') || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Задача :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'U_Task', '-') || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Дедлайн :</p>
													<p className={`text-blue-600 font-medium'}`}>
														{deadLine || '-'}
													</p>
												</div>
												<div className="flex gap-4">
													<p className="font-bold">Cтатус :</p>
													<p className="text-blue-600 font-medium">
														{get(v, 'U_StatusDunner', '-') === 'NotYet'
															? 'Bajarilmagan'
															: 'Bajarilgan' || '-'}{' '}
													</p>
												</div>
											</div>
										)
									})
								)}
							</div>
							<PaginationControls
								currentPage={currentPage}
								onPrevious={oldData}
								onNext={newDatas}
								disablePrevious={currentPage === 0}
								disableNext={finished.length < 10}
							/>
						</div>
					</div>
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<DeleteTask
					getRef={(r) => {
						deleteRef.current = r
					}}
					onClose={() => {
						getNotFinishedDuner()
						getFinishedDuner()
					}}
					executer={executors}
				/>

				<AddTask
					getRef={(r) => {
						addTask.current = r
					}}
					onClose={() => {
						getNotFinishedDuner()
						getFinishedDuner()
					}}
					executer={executors}
				/>
			</>
		</Layout>
	)
}

export default Task
