import React, { memo, useEffect, useRef, useState } from 'react'
import PaymentModalStyle from './PaymentModalStyle'
import {
	AiFillDollarCircle,
	AiFillCreditCard,
	AiFillBank,
} from 'react-icons/ai'
import api, { customFuncsApi, logsUrl } from '../../../api'
import numberWithSpaces from '../../../helpers/numberWithSpaces'
import Modal from 'react-modal'
import moment from 'moment'
import { get } from 'lodash'
import Button from '../../Button'
import { ErrorModal, RefundModal, SuccessModal } from '../index'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { roundCurrency } from '../../../utils'
import { COMPANY_NAME } from '../../../config'
import ConfirmModal from '../../ComfirmModal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '700px',
	},
}

const PaymentModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	loading,
	currencyRate,
	refundShow = true,
	debt = '',
}) => {
	const { getMe } = useSelector((state) => state.main)
	const currentDate = new Date()
	const successModalRef = useRef()
	const errorRef = useRef()
	const year = currentDate.getFullYear()
	const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed
	const day = String(currentDate.getDate()).padStart(2, '0')
	const formattedDate = `${year}-${month}-${day}`
	const refundRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const initDate = {
		dateC: formattedDate,
		amountUzs: null,
		amountUsd: null,
		payme: null,
		numberCard: null,
		terminal: null,
		dollar: currencyRate,
		UsdCard: null,
	}
	const [form, setForm] = useState({ ...initDate })
	const [isRefund, setIsRefund] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const handleShowModal = () => {
		const debtWithPercent = Math.round((debt / 100) * 105)
		const total = parseFloat(
			roundCurrency(
				(+form.amountUsd || 0) +
					(+form.amountUzs || 0) / (form.dollar || 1) +
					(+form.payme || 0) / (form.dollar || 1) +
					(+form.terminal || 0) / (form.dollar || 1),
			),
		)

		console.log('Debt: ', debtWithPercent)
		console.log('Total = ', total)
		if (debt !== '' && debt !== null && debtWithPercent < total) {
			errorRef.current?.open('Сумма к оплате превышает сумму задолжность')
			return
		}
		setConfirmationMessage([
			{
				name: 'Дата:',
				value: form.dateC,
			},
			{
				name: 'Курс:',
				value: form.dollar,
			},
			{
				name: 'USD:',
				value: !!form.amountUsd ? form.amountUsd : 0,
			},
			{
				name: 'Номер usd карты:',
				value: !!form.UsdCard ? form.UsdCard : getMe.U_CashAccount,
			},
			{
				name: 'Наличные UZS',
				value: !!form.amountUzs ? form.amountUzs : 0,
			},
			{
				name: 'Номер uzs карты:',
				value: getMe.U_CashUzsAccount,
			},
			{
				name: 'PayMe:',
				value: !!form.payme ? form.payme : 0,
			},
			{
				name: 'Номер PayMe карта:',
				value: form.numberCard,
			},
			{
				name: 'Терминал:',
				value: !!form.terminal ? form.terminal : 0,
			},
			{
				name: 'Номер терминал карты:',
				value: getMe.U_TerminalAccount,
			},
		])
		setShowModal(true)
	}

	const handleRefund = ({ DocDate, Type, CashSumUzs, CashSumUsd }) => {
		setIsRefund(true)
		customFuncsApi
			.post(`addMoneyChange`, {
				DocDate: DocDate,
				Type: Type,
				CashSumUzs: +CashSumUzs,
				CashSumUsd: +CashSumUsd,
				CashUzsAccount: getMe.U_CashUzsAccount,
				CashUsdAccount: getMe.U_CashAccount,
			})
			.then(() => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: customFuncsApi + `/addMoneyChange`,
						description: 'bitta undiruvga kirganda qaytim qaytarish',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							DocDate: DocDate,
							Type: Type,
							CashSumUzs: +CashSumUzs,
							CashSumUsd: +CashSumUsd,
							CashUzsAccount: getMe.U_CashUzsAccount,
							CashUsdAccount: getMe.U_CashAccount,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				refundRef.current?.clear()
				refundRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsRefund(false)
			})
	}

	const [accountsData, setAccountsData] = useState([])
	const [accountsDataUSD, setAccountsDataUSD] = useState([])
	useEffect(() => {
		api
			.get(
				"ChartOfAccounts?$filter=startswith(Code, '57') and AcctCurrency eq 'UZS' and ExternalCode eq '1'&$select=Code,Name,AcctCurrency",
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setAccountsData(JSON.parse(res.data).value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}, [])

	useEffect(() => {
		api
			.get(
				`ChartOfAccounts?$filter=startswith(Code, '57') and (AcctCurrency eq 'USD' or AcctCurrency eq '${`%23%23`}') &$select=Code,Name,AcctCurrency`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setAccountsDataUSD(JSON.parse(res.data).value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}, [])

	const handleCancel = () => {
		setShowModal(false)
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => {
				setIsOpenModal(false)
				setForm({ ...initDate })
				setShowModal(false)
			}}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<PaymentModalStyle>
				<div className="w-full">
					<h2 className="text-lg font-bold text-zinc-900 text-center mb-6">
						{'Добавить платеж'}
					</h2>
					<div className="grid grid-cols-1 gap-4">
						<div className="flex flex-col md:flex-row gap-4">
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								type={'date'}
								placeholder={'Дата'}
								value={form.dateC}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										dateC: v.target.value,
									}))
								}
							/>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder={'Курс'}
								value={form.dollar}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										dollar: v.target.value,
									}))
								}
							/>
						</div>
						<div className={'flex flex-col md:flex-row gap-4'}>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder={'USD'}
								value={form.amountUsd}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										amountUsd: v.target.value,
									}))
								}
							/>
							<div className="relative">
								<input
									type="text"
									list="usd"
									placeholder={'Номер карта'}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1/2'
									}
									value={form.UsdCard}
									onChange={(v) => {
										setForm((prevValue) => ({
											...prevValue,
											UsdCard: v.target.value,
										}))
										console.log(v.target.value)
									}}
									autoComplete="off"
								/>
								<datalist id="usd">
									{accountsDataUSD.map((v, i) => (
										<option
											key={i}
											label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
											value={get(v, 'Code', '')}
										/>
									))}
								</datalist>
							</div>
						</div>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Наличные UZS'}
							value={form.amountUzs}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									amountUzs: v.target.value,
								}))
							}
						/>
						<div className="flex flex-col md:flex-row gap-4">
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 md:flex-1'
								}
								placeholder={'Карта Payme | Click'}
								value={form.payme}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										payme: v.target.value,
									}))
								}
							/>
							<div className="relative">
								<input
									type="text"
									list="productName"
									placeholder={'Номер карта'}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1/2'
									}
									value={form.numberCard}
									onChange={(v) =>
										setForm((prevValue) => ({
											...prevValue,
											numberCard: v.target.value,
										}))
									}
									autocomplete="off"
								/>
								<datalist id="productName">
									{accountsData.map((v, i) => (
										<option
											key={i}
											label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
											value={get(v, 'Code', '')}
										/>
									))}
								</datalist>
							</div>
						</div>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Терминал'}
							value={form.terminal}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									terminal: v.target.value,
								}))
							}
						/>
					</div>

					<div className={'my-7'}>
						<label
							className={
								'font-medium text-zinc-600 text-[14px] mb-1 flex flex-col gap-2 items-start'
							}
						>
							Общая сумма платежа
							<input
								value={
									roundCurrency(
										(+form.amountUsd || 0) +
											(+form.amountUzs || 0) / (form.dollar || 1) +
											(+form.payme || 0) / (form.dollar || 1) +
											(+form.terminal || 0) / (form.dollar || 1),
									) + ' USD'
								}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-1/2'
								}
							/>
						</label>
					</div>

					<div>
						<Button
							btnStyle={{ backgroundColor: '#243AB5' }}
							onClick={() => {
								refundRef.current?.open()
								setForm({ ...initDate })
							}}
							hoverBtnStyle={{ backgroundColor: '#243AB5' }}
							className="my-4"
						>
							Сдачи
						</Button>
					</div>

					<div className="centerCard">
						<button
							className="btnN"
							onClick={() => {
								onClose()
								setForm({ ...initDate })
							}}
						>
							{'Нет'}
						</button>
						<button
							className="btnY disabled:opacity-50 disabled:cursor-not-allowed"
							onClick={() => handleShowModal()}
							disabled={loading}
						>
							{'Да'}
						</button>
					</div>
				</div>
				<RefundModal
					currencyRate={currencyRate}
					getRef={(r) => (refundRef.current = r)}
					onConfirm={(form) => handleRefund(form)}
					onClose={() => refundRef.current?.close()}
					isLoading={isRefund}
				/>
				<SuccessModal getRef={(r) => (successModalRef.current = r)} />
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<ConfirmModal
					show={showModal}
					data={confirmationMessage}
					onConfirm={() => {
						onConfirm({
							DocDate: form.dateC,
							CurrencyRate: form.dollar,
							AmountUsd: form.amountUsd,
							AmountUzs: form.amountUzs,
							AmountPayme: form.payme,
							NumberCard: form.numberCard,
							Terminal: form.terminal,
							UsdCard: form.UsdCard,
						})
						setShowModal(false)
					}}
					onCancel={handleCancel}
				/>
			</PaymentModalStyle>
		</Modal>
	)
}

export default memo(PaymentModal)
