import React, { memo, useEffect, useRef, useState } from 'react'
import PaymentModalStyle from './PaymentModalStyle'
import Modal from 'react-modal'
import { ErrorModal, SuccessModal } from '../index'
import ClipLoader from 'react-spinners/ClipLoader'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '700px',
	},
}

const FreezedPhonesSelectEmployeeModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	loading,
}) => {
	const successModalRef = useRef()
	const errorRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [employee, setEmployee] = useState('')

	useEffect(() => {
		const ref = {
			open: (phoneEmployee) => {
				console.log('EMployed employee', phoneEmployee)
				setIsOpenModal(true)
				setEmployee(phoneEmployee)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => {
				setIsOpenModal(false)
				setEmployee('')
			}}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<PaymentModalStyle>
				<div className="w-full">
					<h2 className="text-lg font-bold text-zinc-900 text-center mb-6">
						{'Выберите коллектора'}
					</h2>
					<div className="flex justify-center">
						<div className="flex flex-col gap-2">
							<span>Выберите коллектора</span>
							<select
								value={employee}
								onChange={(e) => {
									setEmployee(e.target.value)
								}}
								className="border-[1px] border-[#DFE2E9] p-2 rounded-md w-[300px]"
							>
								<option value="" disabled></option>
								<option value={null} disabled></option>
								<option value="56">Rasul</option>
								<option value="57">Zaynulloh</option>
							</select>
						</div>
					</div>

					<div className="flex items-center justify-between mx-10">
						<button
							className="bg-red-600 rounded-lg hover:bg-red-500 p-4 text-white disabled:opacity-50 disabled:cursor-not-allowed mt-10"
							onClick={() => {
								onClose()
							}}
							disabled={loading}
						>
							{'Отменить'}
						</button>
						<button
							className="bg-green-600 rounded-lg hover:bg-green-500 p-4 text-white disabled:opacity-50 disabled:cursor-not-allowed mt-10"
							onClick={() => {
								onConfirm(employee)
							}}
							disabled={loading || employee === ''}
						>
							<div className="flex gap-3 items-center">
								{loading && (
									<ClipLoader
										loading={true}
										size={10}
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
								)}
								Добавить
							</div>
						</button>
					</div>
				</div>

				<SuccessModal getRef={(r) => (successModalRef.current = r)} />
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
			</PaymentModalStyle>
		</Modal>
	)
}

export default memo(FreezedPhonesSelectEmployeeModal)
