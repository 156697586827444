import React, { useEffect, useRef, useState } from 'react'
import ReportsModalStyle from './reportsStyle'
import Modal from 'react-modal'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../index'
import ClipLoader from 'react-spinners/ClipLoader'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		width: '90%',
		maxHeight: '100vh',
	},
	overlay: {
		background: '#00000099',
	},
}

const ReportsModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading2, setIsLoading2] = useState(false)
	const [data, setData] = useState([])
	useEffect(() => {
		const ref = {
			open: (data) => {
				search(data.ItemCode, data.WhsCode)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	const search = (itemCode, whsCode) => {
		setIsLoading2(true)
		customFuncsApi
			.get(`XsSql/getSerialsInStock?ItemCode=${itemCode}'`, {
				headers: {
					Prefer: 'odata.maxpagesize=40000',
				},
			})
			.then((res) => {
				const resData = res.data.value
				setData(resData)
				setIsLoading2(false)
			})
			.catch((err) => {
				if (err) errorModalRef.current?.open(get(err, 'message', ''))
				setIsLoading2(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<ReportsModalStyle>
				<div className="card">
					<p className="title">Склад остатка</p>
					{isLoading2 ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader2"
						/>
					) : (
						<div className=" overflow-x-auto mb-8 mt-10">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Код товара
										</th>
										<th scope="col" className="px-6 py-4">
											Товар
										</th>

										<th scope="col" className="px-6 py-4">
											ИМЕИ
										</th>
										<th scope="col" className="px-6 py-4">
											Цена продажи
										</th>
										<th scope="col" className="px-6 py-4">
											Коробка
										</th>
										<th scope="col" className="px-6 py-4">
											Ёмкость
										</th>
										<th scope="col" className="px-6 py-4">
											От какого продавца
										</th>
										<th scope="col" className="px-6 py-4">
											Имя склада
										</th>
									</tr>
								</thead>

								<tbody>
									{/* get(getMe, 'Department2.Name', '') === 'DokonManager' ? (
									data.filter((v) => get(v, 'WhsName') === whsName).length >
									0 ? (
																				{/* .filter((v) => get(v, 'WhsName') === whsName)
																							{/* : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									) */}

									{data.map((v, i) => {
										return (
											<tr
												key={i}
												// onClick={() => viewItem(v)}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												<td
													scope="row"
													className="px-6 py-4 font-medium text-gray-900 "
												>
													{get(v, 'ItemCode', '-')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'ItemName', '')} {v['U_Color.Descr']}{' '}
													{get(v, 'U_Memory', '')} {get(v, 'U_Condition', '')}
												</td>

												<td className="px-6 py-4">
													{get(v, 'IntrSerial', '-')}
												</td>
												<td className="px-6 py-4">
													{new Intl.NumberFormat('fr-FR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													})
														.format(get(v, 'U_ShopfrontPrice', '-'))
														.replace(',', '.')}{' '}
												</td>
												<td className="px-6 py-4">{get(v, 'U_Box', '-')}</td>
												<td className="px-6 py-4">
													{get(v, 'U_Percent', '-')}
												</td>
												<td className="px-6 py-4">{get(v, 'CardName', '-')}</td>
												<td className="px-6 py-4">{get(v, 'WhsName', '-')}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</ReportsModalStyle>

			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default ReportsModal
